import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, isSignInWithEmailLink, signInWithPopup, signInWithEmailLink, onAuthStateChanged, GoogleAuthProvider, OAuthProvider, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import type { Auth, User, IdTokenResult } from 'firebase/auth';
import type { IFirebaseUser, TSignInMethod } from '../types/user';

import { setAuthReady } from '@/composables/use-auth-ready';
import { sendAuthLink } from '@/composables/api/user';

interface RuntimeConfigPublic {
    firebaseApiKey: string;
    firebaseAuthDomain: string;
    firebaseDatabaseURL: string;
}

interface NuxtProvide {
    firebaseApp: any;
    firebaseAuth: any;
}

export default defineNuxtPlugin((nuxtApp) => {
    const firebaseUser = useUser();
    const { public: publicRuntimeConfig } = useRuntimeConfig();
    const firebaseConfig = {
        apiKey: publicRuntimeConfig.firebaseApiKey,
        authDomain: publicRuntimeConfig.firebaseAuthDomain,
        databaseURL: publicRuntimeConfig.firebaseDatabaseURL,
        appId: publicRuntimeConfig.firebaseAppID,
        measurementId: publicRuntimeConfig.firebaseMeasurementID,
    } as Partial<RuntimeConfigPublic>;

    const app = initFirebaseApp(firebaseConfig);
    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');

    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const token = await user.getIdTokenResult(true);

            updateFirebaseUser(firebaseUser, user, token);
        }

        setAuthReady(true);
    });

    nuxtApp.hooks.hook('app:mounted', () => {
        setupFirebaseAuthListener(auth, firebaseUser);
    });

    return {
        provide: {
            firebaseApp: app,
            firebaseAuth: auth,
            firebaseEmailLogin: async function (email: string, password: string) {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                const token = await user.getIdTokenResult();

                const userInfo = await getUserInfo();
                if (firebaseUser.value) {
                    firebaseUser.value.firstName = userInfo.firstName;
                    firebaseUser.value.lastName = userInfo.lastName;
                }

                await sync();

                updateFirebaseUser(firebaseUser, user, token);

                return firebaseUser;
            },
            firebaseAppleLogin: async function () {
                await signInWithProvider(auth, appleProvider, firebaseUser);

                return firebaseUser;
            },
            firebaseGoogleLogin: async function () {
                await signInWithProvider(auth, googleProvider, firebaseUser);

                return firebaseUser;
            },
            firebaseMagicLinkRequest: async function (email: string) {
                await sendAuthLink(email);
            },

            firebaseCheckMagicLink: function (url: string) {
                return isSignInWithEmailLink(auth, url);
            },

            firebaseMagicLinkLogin: async function (url: string) {
                if (isSignInWithEmailLink(auth, url)) {
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        email = window.prompt('Please provide your email for confirmation');
                    }

                    const userCredential = await signInWithEmailLink(auth, email || '', url);

                    window.localStorage.removeItem('emailForSignIn');

                    const user = userCredential.user;
                    const token = await user.getIdTokenResult();

                    const userInfo = await getUserInfo();
                    if (firebaseUser.value) {
                        firebaseUser.value.firstName = userInfo.firstName;
                        firebaseUser.value.lastName = userInfo.lastName;
                        firebaseUser.value.phoneNumber = userInfo.phoneNumber;
                        firebaseUser.value.requiresPasswordSetup = userInfo.requiresPasswordSetup || false;
                    }

                    updateFirebaseUser(firebaseUser, user, token);

                    return firebaseUser;
                }
            },

            firebaseEmailSignup: async function (email: string, firstName: string, lastName: string, phoneNumber: string, password: string) {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                const user = userCredential.user;
                const token = await user.getIdTokenResult();

                firebaseUser.value = {
                    id: user.uid,
                    email: user.email || '',
                    firstName,
                    lastName,
                    phoneNumber,
                    token,
                    refreshTimerId: firebaseUser.value?.refreshTimerId || undefined,
                    signInMethod: 'password',
                    requiresPasswordSetup: false,
                };

                await sync();
                await updateMissingData(firstName, lastName, phoneNumber);

                return firebaseUser;
            },

            firebaseRequestPasswordReset: async function (email: string) {
                await sendPasswordResetEmail(auth, email);
            },

            firebaseResetPassword: async function (oobCode: string, newPassword: string) {
                await confirmPasswordReset(auth, oobCode, newPassword);
            },

            updateUserDetails,

            refreshUserDetails,

            firebaseLogout: function () {
                // kill the refresh interval timer
                clearInterval(firebaseUser.value?.refreshTimerId);

                auth.signOut();

                firebaseUser.value = null;
            }
        },
    };
});

function initFirebaseApp (firebaseConfig: Partial<RuntimeConfigPublic>) {
    // @ts-ignore
    return initializeApp(firebaseConfig);
}

function setupFirebaseAuthListener (auth: Auth, firebaseUser: Ref<IFirebaseUser | null>) {
    auth.onIdTokenChanged(async (currentUser: User | null) => {
        if (!currentUser) {
            firebaseUser.value = null;
            return;
        }

        try {
            const idTokenResult: IdTokenResult = await currentUser.getIdTokenResult();
            handleTokenExpiration(idTokenResult, currentUser, firebaseUser);
        } catch (error) {
            handleFirebaseTokenError(error);
        }
    });
}

function handleTokenExpiration (idTokenResult: IdTokenResult, currentUser: User, firebaseUser: Ref<IFirebaseUser | null>) {
    const expiresIn: number = getTokenExpirationDuration(idTokenResult);
    const delay: number = getRefreshDelay(expiresIn);

    updateFirebaseUser(firebaseUser, currentUser, idTokenResult);

    if (!firebaseUser.value) {
        redirectToLogin();
    } else {
        refreshFirebaseToken(delay, currentUser, firebaseUser);
    }
}

function getTokenExpirationDuration (idTokenResult: IdTokenResult): number {
    return new Date(idTokenResult.expirationTime).getTime() - new Date().getTime();
}

function getRefreshDelay (expiresIn: number): number {
    return Math.max(0, expiresIn - 5 * 60 * 1000); // Refresh 5 minutes before expiration
}

function updateFirebaseUser (firebaseUser: Ref<IFirebaseUser | null>, currentUser: User, idTokenResult: IdTokenResult) {
    firebaseUser.value = {
        id: currentUser.uid,
        email: currentUser.email || '',
        phoneNumber: firebaseUser.value?.phoneNumber || '',
        token: idTokenResult,
        firstName: firebaseUser.value?.firstName || undefined,
        lastName: firebaseUser.value?.lastName || undefined,
        refreshTimerId: firebaseUser.value?.refreshTimerId || undefined,
        signInMethod: firebaseUser.value?.signInMethod,
        requiresPasswordSetup: firebaseUser.value?.requiresPasswordSetup,
    };
}

function refreshUserDetails (): Promise<void> {
    return getUserInfo().then((userInfo) => {
        updateUserDetails(userInfo.firstName || '', userInfo.lastName || '', userInfo.phoneNumber || '', userInfo.signInMethod);
    });
}

function updateUserDetails (firstName: string, lastName: string, phoneNumber: string, signInMethod?: TSignInMethod) {
    const firebaseUser = useUser();

    if (firebaseUser.value) {
        firebaseUser.value.firstName = firstName;
        firebaseUser.value.lastName = lastName;
        firebaseUser.value.phoneNumber = phoneNumber;
        firebaseUser.value.signInMethod = signInMethod;
    }
}

function redirectToLogin () {
    const route = useRoute();
    const router = useRouter();
    router.push({
        path: '/login/',
        query: { redirectUrl: route.fullPath, errorCode: 440 },
    });
}

function refreshFirebaseToken (delay: number, currentUser: User, firebaseUser: Ref<IFirebaseUser | null>) {
    if (firebaseUser.value?.refreshTimerId) {
        clearTimeout(firebaseUser.value.refreshTimerId);
    }

    const timerId = setTimeout(async () => {
        try {
            const refreshedToken: string | undefined = await currentUser.getIdToken(true);

            if (firebaseUser.value && refreshedToken) {
                firebaseUser.value.token.token = refreshedToken;
            }
        } catch (error) {
            handleFirebaseTokenError(error);
        }
    }, delay);

    if (firebaseUser.value) {
        firebaseUser.value.refreshTimerId = timerId;
    }
}

function handleFirebaseTokenError (error: any) {
    console.error(error); //eslint-disable-line
    return null;
}

async function signInWithProvider (auth: Auth, provider: GoogleAuthProvider, firebaseUser: Ref<IFirebaseUser | null>) {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const token = await user.getIdTokenResult();

    // set the user with the token and basic info from provider
    firebaseUser.value = {
        id: user.uid,
        email: user.email || '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        token,
        refreshTimerId: firebaseUser.value?.refreshTimerId || undefined,
        signInMethod: provider.providerId as TSignInMethod,
        requiresPasswordSetup: false,
    };

    // sync with our database, so we can get the rest of the user info
    await sync();

    // get the rest of the user info and store locally
    const userInfo = await getUserInfo();

    updateUserDetails(userInfo.firstName || '', userInfo.lastName || '', userInfo.phoneNumber || '', provider.providerId as TSignInMethod);

    updateFirebaseUser(firebaseUser, user, token);
}
