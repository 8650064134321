import { isAuthReady } from '../composables/use-auth-ready';

export default defineNuxtRouteMiddleware((to, from) => {
    if (!isAuthReady.value) {
        return new Promise<void>((resolve) => {
            const unwatch = watch(isAuthReady, (newValue, oldValue) => {
                if (newValue === true) {
                    unwatch(); // Stop watching once the condition is met
                    resolve(); // Resolve the promise
                }
            }, { immediate: false }); // Set 'immediate' to false to not execute on initial setup
        });
    }
});
