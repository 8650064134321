type RouteLocation = {
  path: string;
  query: Record<string, any>;
  hash?: string;
};

type NavigateToOptions = {
  redirectCode: number;
};

export default defineNuxtRouteMiddleware(({ path, query, hash }: RouteLocation, from: RouteLocation) => {
    if (path === '/' || !path.endsWith('/')) {
        return;
    }

    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute: RouteLocation = { path: nextPath, query, hash };

    // Options for navigateTo (assuming that they exist based on your original JavaScript code)
    const options: NavigateToOptions = { redirectCode: 308 };

    return navigateTo(nextRoute, options);
});
