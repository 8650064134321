// @ts-nocheck
import type {
    AvailableRouterMethod,
    NitroFetchOptions,
    NitroFetchRequest,
    TypedInternalResponse,
} from 'nitropack';

import { isAuthReady } from './use-auth-ready';

export async function useAPI<
    T = unknown,
    R extends NitroFetchRequest = NitroFetchRequest
> (
    request: R,
    opts?: NitroFetchOptions<R, AvailableRouterMethod<R>>,
    omitToken?: boolean
): Promise<TypedInternalResponse<R, T>> {
    const baseURL = useRuntimeConfig().public.apiBaseURL as string;
    const user = useUser();

    // if the firebase auth isn't ready (i.e. still refreshing the token), wait for it to be ready
    if (!isAuthReady.value) {
        await new Promise((resolve) => {
            const stop = watchEffect(() => {
                if (isAuthReady.value) {
                    resolve();
                    stop(); // Stop the watcher once the token is ready
                }
            });
        });
    }

    const token = user.value?.token.token;
    const headers = (token && !omitToken ? { Authorization: `Bearer ${token}` } : {}) as HeadersInit;
    // add account context slug to headers if we are in ACCOUNT_DIRECT mode
    const { isAccountDirect, accountContextSlug } = useAppMode();
    if (isAccountDirect) {
        headers['X-Account-Context'] = accountContextSlug;
    }
    const customFetch = $fetch.create({
        baseURL,
        headers,
    });

    return customFetch(request, opts);
}
