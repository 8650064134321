import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_93R5OzQx1y5ZMeta } from "/opt/buildhome/repo/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as notificationsvs4SYXstdeMeta } from "/opt/buildhome/repo/pages/account/notifications.vue?macro=true";
import { default as paymentscnPfEikkxIMeta } from "/opt/buildhome/repo/pages/account/payments.vue?macro=true";
import { default as personal_45infoy3JsSFp4iqMeta } from "/opt/buildhome/repo/pages/account/personal-info.vue?macro=true";
import { default as securityXOdRHh5FdmMeta } from "/opt/buildhome/repo/pages/account/security.vue?macro=true";
import { default as confirmHUJ8ZD3q2JMeta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexJnORAIqgu8Meta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93RRcroN7dmeMeta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93FZSdQsAqSYMeta } from "/opt/buildhome/repo/pages/gratuity/[accessKey].vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as set_45password7SadfZikENMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
export default [
  {
    name: _4047Xi4OILrTdMeta?.name ?? "404",
    path: _4047Xi4OILrTdMeta?.path ?? "/404",
    meta: _4047Xi4OILrTdMeta || {},
    alias: _4047Xi4OILrTdMeta?.alias || [],
    redirect: _4047Xi4OILrTdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HxQxjzxhjNMeta?.name ?? "slug",
    path: _91_46_46_46slug_93HxQxjzxhjNMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93HxQxjzxhjNMeta || {},
    alias: _91_46_46_46slug_93HxQxjzxhjNMeta?.alias || [],
    redirect: _91_46_46_46slug_93HxQxjzxhjNMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93R5OzQx1y5ZMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_93R5OzQx1y5ZMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_93R5OzQx1y5ZMeta || {},
    alias: _91_91filter_93_93R5OzQx1y5ZMeta?.alias || [],
    redirect: _91_91filter_93_93R5OzQx1y5ZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexy4DdxYIhQyMeta?.name ?? "account",
    path: indexy4DdxYIhQyMeta?.path ?? "/account",
    meta: indexy4DdxYIhQyMeta || {},
    alias: indexy4DdxYIhQyMeta?.alias || [],
    redirect: indexy4DdxYIhQyMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsvs4SYXstdeMeta?.name ?? "account-notifications",
    path: notificationsvs4SYXstdeMeta?.path ?? "/account/notifications",
    meta: notificationsvs4SYXstdeMeta || {},
    alias: notificationsvs4SYXstdeMeta?.alias || [],
    redirect: notificationsvs4SYXstdeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: paymentscnPfEikkxIMeta?.name ?? "account-payments",
    path: paymentscnPfEikkxIMeta?.path ?? "/account/payments",
    meta: paymentscnPfEikkxIMeta || {},
    alias: paymentscnPfEikkxIMeta?.alias || [],
    redirect: paymentscnPfEikkxIMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infoy3JsSFp4iqMeta?.name ?? "account-personal-info",
    path: personal_45infoy3JsSFp4iqMeta?.path ?? "/account/personal-info",
    meta: personal_45infoy3JsSFp4iqMeta || {},
    alias: personal_45infoy3JsSFp4iqMeta?.alias || [],
    redirect: personal_45infoy3JsSFp4iqMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securityXOdRHh5FdmMeta?.name ?? "account-security",
    path: securityXOdRHh5FdmMeta?.path ?? "/account/security",
    meta: securityXOdRHh5FdmMeta || {},
    alias: securityXOdRHh5FdmMeta?.alias || [],
    redirect: securityXOdRHh5FdmMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmHUJ8ZD3q2JMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmHUJ8ZD3q2JMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmHUJ8ZD3q2JMeta || {},
    alias: confirmHUJ8ZD3q2JMeta?.alias || [],
    redirect: confirmHUJ8ZD3q2JMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexJnORAIqgu8Meta?.name ?? "experiences-parentId-serviceId",
    path: indexJnORAIqgu8Meta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexJnORAIqgu8Meta || {},
    alias: indexJnORAIqgu8Meta?.alias || [],
    redirect: indexJnORAIqgu8Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93RRcroN7dmeMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_93RRcroN7dmeMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93RRcroN7dmeMeta || {},
    alias: _91bookingId_93RRcroN7dmeMeta?.alias || [],
    redirect: _91bookingId_93RRcroN7dmeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_93FZSdQsAqSYMeta?.name ?? "gratuity-accessKey",
    path: _91accessKey_93FZSdQsAqSYMeta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_93FZSdQsAqSYMeta || {},
    alias: _91accessKey_93FZSdQsAqSYMeta?.alias || [],
    redirect: _91accessKey_93FZSdQsAqSYMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordR3tuU8TAkoMeta?.name ?? "reset-password",
    path: reset_45passwordR3tuU8TAkoMeta?.path ?? "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    alias: reset_45passwordR3tuU8TAkoMeta?.alias || [],
    redirect: reset_45passwordR3tuU8TAkoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45password7SadfZikENMeta?.name ?? "set-password",
    path: set_45password7SadfZikENMeta?.path ?? "/set-password",
    meta: set_45password7SadfZikENMeta || {},
    alias: set_45password7SadfZikENMeta?.alias || [],
    redirect: set_45password7SadfZikENMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/set-password.vue").then(m => m.default || m)
  }
]