import { useAppMode } from '~/composables/use-app-mode';
import useContextSlug from '~/composables/use-context-slug';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { resolveAccountContext } = useContextSlug();
    // If we are already on the 404 page, don't check for the account context slug
    if (to.path === '/404') {
        return Promise.resolve();
    }

    const { isAccountDirect } = useAppMode();

    // If we are in ACCOUNT_DIRECT mode, we need to fetch the Account Context from the slug
    if (isAccountDirect) {
        try {
            await resolveAccountContext();
        } catch (error) {
            return navigateTo('/404');
        }
    } else {
        return Promise.resolve(); // Resolve the promise immediately if we are not in ACCOUNT_DIRECT mode
    }
});
