export default defineNuxtPlugin(() => {
    addRouteMiddleware(
        'auth',
        (to) => {
            const user = useUser();
            const isPrivate = to.meta.private;
            const isLoggedIn = Boolean(user.value);

            if (!isLoggedIn && isPrivate) {
                return navigateTo('/login');
            }

            if (user.value?.requiresPasswordSetup === true && to.path !== '/set-password') {
                return navigateTo({
                    path: '/set-password',
                    query: to.query
                });
            }

            if (to.meta.isLoginPage && isLoggedIn) {
                return navigateTo('/');
            }
        },
        { global: true }
    );
});
