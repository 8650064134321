import { StorageSerializers, useStorage } from '@vueuse/core';
import type { IFirebaseUser } from '~/types/user';

const user = useStorage<IFirebaseUser | null>('system-user', () => null, undefined, {
    serializer: StorageSerializers.object
});

export default function (): Ref<IFirebaseUser | null> {
    return user;
}
