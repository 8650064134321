export default defineNuxtRouteMiddleware((to) => {
    // when on the [...slug] page, set the layout to 'home-account-direct' if in that mode
    if (to.name === 'slug') {
        const { isAccountDirect } = useAppMode();

        if (isAccountDirect) {
            setPageLayout('home-account-direct');
        }
    }
});
