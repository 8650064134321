import { TAppMode } from '~/types/app-modes';
import { APP_MODES } from '~/constants/app-modes';

export function useAppMode (): { appMode: TAppMode; isPublicDirect: boolean; isAccountDirect: boolean; accountContextSlug?: string } {
    const hostname = window.location.hostname;
    const subdomains = hostname.split('.');
    const { public: publicRuntimeConfig } = useRuntimeConfig();

    // PUBLIC_DIRECT: https://book.goexpond.com/
    // ACCOUNT_DIRECT: https://context-slug.book.goexpond.com/
    const expectedSubdomains = publicRuntimeConfig.appEnv === 'local' ? 2 : 3; // when on dev/prod the `.com` is included in the subdomain split (so has 3 parts), but on local it isn't (so only 2)
    const appMode = subdomains.length === expectedSubdomains ? APP_MODES.PUBLIC_DIRECT : APP_MODES.ACCOUNT_DIRECT;

    return {
        appMode,
        isPublicDirect: appMode === APP_MODES.PUBLIC_DIRECT,
        isAccountDirect: appMode === APP_MODES.ACCOUNT_DIRECT,
        accountContextSlug: appMode === APP_MODES.ACCOUNT_DIRECT ? subdomains[0] : undefined,
    };
}
